export default class AssessmentSkillDTO {
  constructor() {
    this.id = null;
    this.description = null;
    this.jobAssessmentId = null;
    this.skillAssessment = "VERY_POOR";
    this.skillId = null;
    this.skill = null;
  }

  assessmentRating() {
    let rating = 1;
    switch (this.skillAssessment) {
      case "VERY_POOR":
        rating = 1;
        break;
      case "POOR":
        rating = 2;
        break;
      case "NORMAL":
        rating = 3;
        break;
      case "GOOD":
        rating = 4;
        break;
      case "VERY_GOOD":
        rating = 5;
        break;
      default:
        rating = 1;
    }
    return rating;
  }
}
