<template>
  <app-page-layout :title="$t('hr.hiring.crud.assessment.evaluatee')" @close="leavePage">
    <template #content>
      <template v-if="loading">
        <v-layout row wrap align-center>
          <v-flex xs4>
            <v-progress-circular :value="100" indeterminate color="primary" />
          </v-flex>
        </v-layout>
      </template>
      <template v-else>
        <v-layout row wrap v-if="assessment.applicant">
          <v-flex xs12>
            <v-list-item class="px-0">
              <v-list-item-avatar size="80" class="mr-3" :color="assessment.applicant.photoLink ? 'white' : 'primary'">
                <v-img
                  v-if="assessment.applicant.photoLink"
                  :src="assessment.applicant.photoLink"
                  :lazy-src="assessment.applicant.photoLink"
                >
                  <template v-slot:placeholder>
                    <v-layout fill-height align-center justify-center ma-0>
                      <v-progress-circular indeterminate color="grey lighten-5" />
                    </v-layout>
                  </template>
                </v-img>
                <span
                  v-else
                  style="width: 120px; height: 120px; display: flex; align-items: center; justify-content: center; font-size: 30px"
                  class="white--text font-weight-bold"
                >
                  {{ assessment.applicant.name.substring(0, 1).toUpperCase()
                  }}{{ assessment.applicant.surName.substring(0, 1).toUpperCase() }}
                </span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="title"
                  >{{ assessment.applicant.name }} {{ assessment.applicant.surName }}</v-list-item-title
                >
                <v-list-item-subtitle class="caption">{{ assessment.applicant.phone }}</v-list-item-subtitle>
                <v-list-item-subtitle class="caption">{{ assessment.applicant.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-layout>
        <add-skill-assessment :added-skills="dto.detailDtoList" @added="onHandleAddSkill" />
        <validation-observer ref="form">
          <v-layout row wrap align-center v-for="item in dto.detailDtoList" :key="item.skillId">
            <v-flex xs5>
              <v-list-item-title> {{ item.skillName }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t(`hr.hiring.skill_type.${item.skillType.toLowerCase()}`) }}</v-list-item-subtitle
              >
            </v-flex>
            <v-flex xs3>
              <v-rating
                :value="getRating(item.skillAssessment)"
                @input="onChangeRating($event, item)"
                hover
                dense
                :color="getColor(item.skillAssessment)"
                background-color="grey lighten-1"
              />
            </v-flex>
            <v-flex xs2>
              <validation-provider
                :name="`weight`"
                :rules="`required|numeric|max_value:100|min_value:0`"
                v-slot="{ errors }"
              >
                <v-text-field
                  type="number"
                  :label="$t('hr.hiring.weight')"
                  v-model.number="item.weight"
                  :error-messages="errors"
                  :max="100"
                  :min="0"
                />
              </validation-provider>
            </v-flex>
            <v-flex xs2 class="text-right">
              <div class="body-2">{{ $t("hr.hiring.rate") }}</div>
              <span class="font-weight-bold body-2">% {{ item.skillWeight }}</span>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </validation-observer>
      </template>
    </template>
    <template #footer>
      <app-page-footer create @save="validateAndSave"></app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  import { ASSESSMENT_DETAIL } from "../query";
  import EvaluateeDTO from "../model/EvaluateeDTO";

  /**
   * @project idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 7.07.2020
   */

  export default {
    name: "HiringEvaluate",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      AddSkillAssessment: () => import("../components/AddSkillAssessment")
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data: () => ({
      loading: false,
      evaluator: null,
      assessment: {},
      dto: new EvaluateeDTO(),
      detailDtoList: []
    }),
    watch: {
      id: {
        handler() {
          this.fetchSelectedItemById();
        },
        immediate: true
      },
      "dto.detailDtoList": {
        handler(evaluatorList) {
          this.dto.calculation(evaluatorList);
        },
        deep: true
      }
    },
    methods: {
      leavePage() {
        this.$emit("closed:create");
        this.$router.push({ name: "hiring" });
      },
      setAssessment(average) {
        let rating = null;
        switch (average) {
          case 1:
            rating = "VERY_POOR";
            break;
          case 2:
            rating = "POOR";
            break;
          case 3:
            rating = "NORMAL";
            break;
          case 4:
            rating = "GOOD";
            break;
          case 5:
            rating = "VERY_GOOD";
            break;
          default:
            rating = "NA";
        }
        this.assessment.assessment = rating;
      },
      getRating(assessment) {
        let rating = 1;
        switch (assessment) {
          case "VERY_POOR":
            rating = 1;
            break;
          case "POOR":
            rating = 2;
            break;
          case "NORMAL":
            rating = 3;
            break;
          case "GOOD":
            rating = 4;
            break;
          case "VERY_GOOD":
            rating = 5;
            break;
          default:
            rating = null;
        }
        return rating;
      },
      onChangeRating(rating, item) {
        let newRating = null;
        switch (rating) {
          case 1:
            newRating = "VERY_POOR";
            break;
          case 2:
            newRating = "POOR";
            break;
          case 3:
            newRating = "NORMAL";
            break;
          case 4:
            newRating = "GOOD";
            break;
          case 5:
            newRating = "VERY_GOOD";
            break;
          default:
            newRating = "NA";
        }
        item.skillAssessment = newRating;
      },
      getColorRating() {
        let color = null;
        switch (this.rating) {
          case 1:
            color = "red";
            break;
          case 2:
            color = "red lighten-1";
            break;
          case 3:
            color = "orange lighten-1";
            break;
          case 4:
            color = "green lighten-1";
            break;
          case 5:
            color = "green darken-2";
            break;
          default:
            color = "red";
        }
        return color;
      },
      getColor(rating) {
        let color = null;
        switch (rating) {
          case "VERY_POOR":
            color = "red";
            break;
          case "POOR":
            color = "red lighten-2";
            break;
          case "NORMAL":
            color = "orange lighten-1";
            break;
          case "GOOD":
            color = "green lighten-1";
            break;
          case "VERY_GOOD":
            color = "green darken-2";
            break;
          default:
            color = "red";
        }
        return color;
      },
      validateAndSave() {
        this.$refs.from.validate("evaluate").then(valid => {
          if (valid) {
            this.$api_hiring.assessmentDetailService
              .evaluatorAssessment(this.dto)
              .then(({ data }) => {
                if (!data.error) {
                  this.$emit("success:assessment");
                  this.leavePage();
                }
              })
              .catch(e => this.$helpers.showNotification(e.message));
          }
        });
      },
      onHandleAddSkill(skillAssessment) {
        this.dto.addSkillAssessment(skillAssessment).catch(e => {
          this.$helpers.showNotification(e.message);
        });
      },
      onHandleDelete(skillAssessment) {
        this.dto
          .removeSkillAssessment(skillAssessment)
          .then(() => {})
          .catch(e => {
            this.$helpers.showNotification(e.message);
          });
      },
      async fetchSelectedItemById() {
        this.loading = true;
        await this.$apollo
          .query({
            client: "hiring",
            query: ASSESSMENT_DETAIL,
            variables: {
              id: this.$helpers.decodeId(this.id)
            },
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.assessment = data.jobAssessmentById;
              let evaluator = data.jobAssessmentById.evaluatorList.find(
                item => item.employee.id === this.$store.state.auth.user.id
              );
              this.dto.evaluatorId = evaluator.employee.id;
              this.dto.assessmentId = data.jobAssessmentById.id;
              this.dto.addSkills(data.jobAssessmentById.jobPosting.topSkills);
            }
          })
          .catch(() => {
            this.$emit("detail-loaded:error");
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  };
</script>

<style scoped></style>
