import gql from "graphql-tag";

export const EMPLOYEE_HIRING_EVALUATION = gql`
  query filterByEmployeeWithPaginateJobApplicantAssessment(
    $criteria: EvaluatorEmployeeFilterInput!
    $pagination: Pagination!
  ) {
    filterByEmployeeWithPaginateJobApplicantAssessment(criteria: $criteria, pagination: $pagination) {
      pageInfo {
        totalElements
      }
      content {
        id
        assessmentDate
        applicantLastJob
        assessment
        applicant {
          id
          name
          surName
          email
          photoLink
          phone
        }
        jobPosting {
          postTitle
          postName
        }
        evaluatorAssessmentResults {
          evaluatorEmployee {
            id
          }
          evaluatorRating
        }
        evaluatorList {
          id
          employee {
            id
            identityNumber
            firstName
            lastName
            workEmail
          }
        }
        numericAssessmentScore
        status
      }
    }
  }
`;

export const EVALUATOR_RESULT = gql`
  query findByAssessmentAndEmployeeId($id: ID!, $empId: ID!) {
    findByAssessmentAndEmployeeId(id: $id, empId: $empId) {
      applicantAssessment {
        applicant {
          name
          surName
          phone
          email
          dateOfBirth
          photoLink
        }
      }
      evaluatorWeight
      evaluatorRating {
        evaluatorRating
      }
      skillAssessmentList {
        skillAssessment
        skillWeight
        skillScore
        numericSkillAssessment
        skill {
          skillName
        }
      }
    }
  }
`;

export const ASSESSMENT_DETAIL = gql`
  query jobAssessmentById($id: ID!) {
    jobAssessmentById(id: $id) {
      id
      applicantLastJob
      applicantLastPositionLevel
      applicant {
        id
        name
        surName
        photoLink
        email
        resumeLink
      }
      jobPosting {
        id
        topSkills {
          id
          skillName
          skillType
          link
        }
      }
      evaluatorList {
        id
        employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
